.polycamera {
  margin: 0;
  padding-top: 188px;

  h2 {
    backdrop-filter: blur(12.3px);
    -webkit-backdrop-filter: blur(12.3px);

    span {
      color: #3769EC;
    }
  }

  &-desc {
    opacity: 70%;
    font-size: 16px;
    width: 500px;
    letter-spacing: 0.1px;
    padding-top: 20px;
    margin: 0 0 30px 0;
  }

  &-img {
    background: none;
    border: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    margin: 0;
    position: relative;
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin-bottom: 78px;
    user-select: none;


    img {
      display: block;
      filter: drop-shadow(10px 10px 80px #00000026);
      width: 649.09px;
      height: 364.64px;

    }
  }


  &-reasons {
    padding-top: 100px;
    margin-bottom: 30px;

    &-title {
      margin-bottom: 41px;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 0.5fr);
      gap: 41px 24px;

      &-item {

        & svg {
          margin-bottom: 16px;
        }
      }
    }
  }

  &-img-airport {
    width: 100%;
    margin: 0;

    & img {
      mix-blend-mode: lighten;
      overflow: hidden;
      transform: scale(1.03);
      height: 659px;
      width: auto;
    }
  }

  &-multifocal {
    width: 1250px;
    margin-bottom: 70px;

    &-text {
      &-title {
        color: #3769EC;
      }

      &-desc {
        opacity: 100%;
        color: #FFFFFF;
      }
    }
  }

  &-videosensor {
    padding-top: 64px;
    margin-bottom: 85px;

    &-text {
      &-desc {
        color: #FFFFFF;
        width: 593px;
      }

      &-title {
        color: #3769EC;
      }
    }

    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
    }

    &-img::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 150px;
      height: 50px;
      box-shadow: 0 0 200px 100px rgba(41, 128, 214, 0.8);
      z-index: -1;
      transform: translate(-50%, -50%);
    }
  }

  &-lenses {
    padding-top: 121px;
    margin-bottom: 84px;

    &-text {
      width: 520px;
    }
  }

  &-hdr-technology {
    padding-top: 49px;

    &-text {
      &-desc {
        width: 539px;
      }
    }

    &-img {
      display: flex;
      gap: 50px;
      margin-bottom: 126px;

      & div {
        margin-bottom: 42px;
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

      }

      &-name {
        width: 104px;
      }
    }
  }

  &-camera-control {
    width: 1183px;
    padding-top: 121px;
    margin-bottom: 110px;

    &-img {
      & img {
        border: 3px solid #3769EC;
        border-radius: 10px;
        box-shadow: 0 0 174px rgba(41, 128, 214, 0.8);
      }
    }

    &-text {
      &-title {
        width: 500px;
      }

      &-desc {
        width: 550px;
      }

    }

  }

  &-archive-video {
    width: 1328px;
    padding-top: 120px;
    margin-bottom: 76px;

    &-text {
      &-title {
        color: #3769EC;
      }
    }
  }


  &-products {
    padding-top: 88px;
    margin-bottom: 88px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      &-desc {
        opacity: 60%;
        width: 500px;
      }
    }
  }

  &-products-list {
    display: flex;
    gap: 50px;

    &-details {
      padding: 0 15px;
      height: 40px;
      background-color: #FFFFFF1A;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 41px;
      color: #FFFFFF99;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.05px;
      font-family: 'TildaSansMedium', sans-serif;

      & a {
        cursor: pointer;
        height: 40px;
        margin: 0 0 0 15px;
        color: #FFFFFF;
        opacity: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'TildaSansBold', sans-serif;

        & svg {
          margin-left: 15px;

          path, rect {
            transition: stroke 0.3s ease, fill 0.3s ease;
          }

        }

        &:hover {
          color: #4670FE;

          & svg {
            & path {
              stroke: #4670FE;
            }

            & rect {
              fill: #4670FE;
            }
          }
        }
      }
    }

    &-item {
      width: 410px;
      height: 446px;
      background: #FFFFFF0D;
      border-radius: 24px;
      outline: 2px solid #346290;
      padding: 36px;
      box-shadow: 10px 10px 37.7px 0px #000000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 74px;
      transition: box-shadow 1s ease, outline 1s ease;

      &:hover {
        outline: 3px solid #2980D6;
        box-shadow: 0 0 30px 0 #3566E8;
      }

      &-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
        mix-blend-mode: luminosity;

        & img {
          box-shadow: 10px 10px 80px 0 #00000026;
        }
      }

      &-title {
        font-family:  'TildaSansSemiBold', sans-serif;
        font-size: 28px;
        line-height: 28px;
        color: #B3BFE2;
        margin-bottom: 45px;

        & span {
          color: #4F80FF;
        }
      }

      &-desc {
        color: #FFFFFFD9;
        margin-bottom: 40px;
      }

      & button {
        width: 100%;
      }
    }
  }
}

.group {
  position: absolute;
  top: 41.3%;
  left: 72%;
}
.group2 {
  position: absolute;
  top: 64%;
  left: 9%;
}