.footer {
    width: 100%;
    height: 716px;
    border-top: 1px solid #FFFFFF21;

    &__content {
        padding: 123px 200px 0 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-navbar {
            display: flex;

            &-container {
                max-width: 368px;
                width: 100%;

                &-name {
                    font-size: 18px;
                    color: #7F8CA6;
                    line-height: 25px;
                    letter-spacing: 0.2px;

                }

                &-link {
                    font-family: 'TildaSansRegular', sans-serif;
                    font-size: 15px;
                    line-height: 21px;
                    letter-spacing: 0.1px;
                }
            }


        }


        &-contacts {
            display: flex;
            flex-direction: column;


            &-phone {
                font-family: 'TildaSansLight', sans-serif;
                padding-top: 25px;
                margin-bottom: 4px;
                font-size: 16px;
                line-height: 22.4px;
                letter-spacing: 0;
            }

            &-mail {
                font-family: 'TildaSansLight', sans-serif;
                margin-bottom: 22px;
                font-size: 16px;
                line-height: 22.4px;
                letter-spacing: 0.5px;
            }

            &-socials {
                width: 56px;
                display: flex;
                justify-content: space-between;


                & svg {
                    cursor: pointer;

                    & path {
                        transition: 0.3s ease;
                    }

                    &:hover {
                        & path {
                            stroke: #4670FE;
                        }
                    }
                }
            }
        }

        &-adress {
            display: flex;
            flex-direction: column;
            align-items: end;

            & button {
                margin: 11px 11px 17px 0;
                width: 170px;
            }

            & div {
                margin: 0 11px 0 0;
                opacity: 80%;
                display: flex;
                flex-direction: column;
                align-items: end;

                & span {
                    letter-spacing: 0.25px;
                    font-size: 16px;
                }

                & span:first-child {
                    padding-top: 20px;
                    margin-bottom: 3px;
                }
            }
        }
    }

    .adress-time {
        font-size: 14px;
        opacity: 60%;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 18px 200px;

        & span,
        a {
            font-family: 'TildaSansLight', sans-serif;
            font-size: 14px;
            line-height: 18.2px;
            opacity: 60%;
            letter-spacing: 0.4px;
        }
    }
}