.content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__window {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-name {
                outline: 1px solid #FFFFFF14;
                background: #FFFFFF0A;
                backdrop-filter: blur(32px);
                -webkit-backdrop-filter: blur(32px);
                line-height: 16px;
                padding: 0 30px;
                margin-bottom: 24px;
                border-radius: 53px;

                & span {
                    font-family: 'TildaSansRegular', sans-serif;
                    font-size: 12px;
                    height: 32px;
                    letter-spacing: 0.18em;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    text-transform: uppercase;
                }

            }

            &-title {
                font-family: "TildaSansBold", sans-serif;
                font-size: 38px;
                line-height: 45.6px;
                letter-spacing: -1px;
                text-align: center;
                z-index: 1;
                margin-bottom: 32px;
            }

            &-desc {
                font-family: 'TildaSansRegular', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: -0.5px;
                margin-bottom: 50px;
                color: #FFFFFF;
            }

            &-button {
                width: 176px;

                &-desc {
                    margin-top: 20px;
                    font-size: 16px;
                    opacity: 60%;
                }
            }
        }


        &-img {
            margin-bottom: 24px;
            width: 1140px;
            height: 640px;
            background: rgba(88, 115, 146, 0.05);
            border: 1px solid rgba(237, 231, 225, 0.1);
            border-radius: 12px;
            backdrop-filter: blur(40px);
            -webkit-backdrop-filter: blur(40px);
            mix-blend-mode: luminosity;


        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;

            &-item {
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(40px);
                -webkit-backdrop-filter: blur(40px);
                display: flex;
                flex-direction: column;
                width: 364px;
                min-height: 188px;
                padding: 16px;
                overflow: hidden;
                border-radius: 12px;
                border: 1px solid rgba(237, 231, 225, 0.1);

                & svg {
                    margin-bottom: 12px;
                }


                &-name {
                    font-family: 'TildaSansMedium', sans-serif;
                    color: #FFFFFF;
                    font-size: 18px;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    text-align: left;
                    margin-bottom: 16px;
                }

                &-desc {
                    font-family: 'TildaSansRegular', sans-serif;
                    color: #FFFFFF99;
                    text-align: start;
                }
            }
        }


        &__second {
            width: 1140px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &-text {
                width: 500px;
                display: flex;
                flex-direction: column;
                align-items: start;
                text-align: start;

                &-title {
                    text-align: start;
                }

                &-desc {
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.5px;
                    color: #FFFFFF;
                    margin: 0;
                }
            }

        }

        &__main {
            font-family: 'TildaSansRegular', sans-serif;
            padding-top: 220px;
            margin-bottom: 200px;

            &-text {
                &-name {
                    margin-bottom: 34px;
                }

                &-title {
                    font-size: 72px;
                    line-height: 84px;

                    & span {
                        color: #4670FF;
                    }
                }

                &-desc {
                    opacity: 70%;
                    width: 470px;
                    margin-bottom: 54px;
                }
            }
        }
    }
}

.ellipse {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &-1 {
        top: 10%;
        left: 40%;
    }

    &-2 {
        top: 47%;
        left: 45%;
    }

    &-3 {
        top: 68%;
        left: -40%;
    }

    &-4 {
        top: 95%;
        left: 40%;
    }

    &-5 {
        top: 10%;
        left: 35%;
    }

    &-6 {
        top: 83%;
        left: -35%;
    }

    &-7 {
        top: 45%;
        left: -40%;
    }
}
