button {
    position: relative;
    width: 140px;
    height: 40px;
    padding: 8px 16px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    font-family: 'TildaSansRegular', sans-serif;
    background-color: rgba(34, 69, 185, 1);
    background-image: linear-gradient(318.96deg, #7F8CEB -1.9%, rgba(174, 177, 245, 0) 17.34%, #DBDDFF 87.36%);
    background-blend-mode: soft-light;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow:
        0px 0px 16px 0px rgba(146, 84, 222, 0.32),
        0px 2px 2px 0px rgba(0, 0, 0, 1);

    overflow: hidden;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #1F3C9A;
    }

    &:active {
        background-color: #1C3483;
    }

    &:focus {
        outline: 1px solid #4670FE;
        outline-offset: 2px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 1.4px;
        background: linear-gradient(135.41deg, #96AFEA 0%, #041246 106.82%);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        }
}

