*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: 'TildaSansLight', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 1;
  color: #FFFFFF;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  background-color: #000712;
  transition: 0.2s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1177px;
    background-color: #000712;
    background-image: url('./pages/MainPage/image/background-img.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'TildaSansBold', sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
}

h2 {
  font-size: 72px;
  line-height: 84px;
  text-align: center;
  letter-spacing: -1px;

}

h3 {
  font-size: 48px;
  line-height: 84px;
  text-align: center;
  color: #FFFFFF;

}

ul {
  padding: 15px 0 0 0;
  margin: 0 0 47px 0;
}

li {
  cursor: pointer;
  list-style-type: none;
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #FFFFFF;
  opacity: 90%;
  transition: color 0.3s ease;

  &:hover {
    color: #4670FE;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  user-select: none;
}