.norma-s1 {
  padding-top: 300px;
  margin-bottom: 150px;

  &-text {
    &-desc {
      opacity: 60%;
    }
  }

  &-img {
    position: relative;
    z-index: 1;
  }

  &-img::after {
    content: '';
    position: absolute;
    top: 70%;
    left: 35%;
    width: 75px;
    height: 25.5px;
    box-shadow: 0 0 200px 100px rgba(41, 128, 214, 0.8);
    border-radius: 30%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }


}

.omniclient {
  padding-top: 150px;
  margin-bottom: 278px !important;

  &-text {
    &-title {
      width: 450px;
    }

    &-desc {
      opacity: 60%;
      width: 530px;
    }
  }

  &-button {
    width: 176px;
    margin-bottom: 20px;
  }
}

.clipboard-history {
  padding-top: 131px;
  margin-bottom: 145px;
}

.applicability {
  margin-bottom: 209px;

  &-text {
    &-desc {
      opacity: 60%;
    }
  }

  &-icon {

    &-group {
      margin-right: 129px;
    }

    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: default ;
  }

}
.icon-1, .icon-2, .icon-3, .icon-4, .icon-5 {
  width: 150px;
  height: 170px;
  border-radius: 44px;
  border: 2px solid #4770FF;
  background: linear-gradient(180deg, #041B32 0%, rgba(2, 11, 20, 0.47) 100%);
  color: #CACACA;
  font-family: 'TildaSansSemiBold', sans-serif;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  box-shadow: 8px 8px 25px 0px #00000073;
  transition: 2s ease;
  animation: move 10s infinite alternate;

  & svg path {
    stroke: #346290;
    transition: 2s ease;
  }

  &:hover {
    box-shadow: 0px 0px 25px 0px #4770FF;

    & svg path {
      stroke: #4770ff;
    }
  }
}

.icon {
  &-1 {
    margin-left: 399px;
    animation-delay: 0s;
  }

  &-2 {
    margin-top: -53px;
    animation-delay: 0.2s;
  }

  &-3 {
    margin-top: -82px;
    margin-left: 229px;
    animation-delay: 0.4s;
  }

  &-4 {
    margin-top: -25px;
    margin-left: 429px;
    animation-delay: 0.6s;
  }

  &-5 {
    margin-top: -92px;
    margin-left: 52px;
    animation-delay: 0.8s;
  }
}

@keyframes move {
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(5px) translateY(5px);
  }
  50% {
    transform: translateX(-5px) translateY(-5px);
  }
  75% {
    transform: translateX(5px) translateY(-5px);
  }
  100% {
    transform: translateX(-5px) translateY(5px);
  }
}

.main-polycamera {
  padding-top: 144px;

  &-text {

    &-title {
      width: 400px;
    }

    &-desc {
      opacity: 70%;
      width: 670px;
    }
  }
}

.faqs {
  padding-top: 93px;
  margin-bottom: 126px;

  &-content {
    width: 700px;
  }

  &-text {
    align-items: start !important;
  }

  &-title {
    display: flex;
    text-align: left;
    font-size: 36px;
    margin-bottom: 24px;
  }

  &-desc {
    letter-spacing: -0.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    opacity: 40%;
    display: flex;
    text-align: left;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 49px !important;
  }

  &-list {
    &-item {
      box-sizing: content-box;
      font-family: 'TildaSansSemiBold', sans-serif;
      padding-top: 32px;
      cursor: pointer;
      height: 58px;
      border-top: 1px solid #FFFFFF1A;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 24px;

      &:first-child {
        padding: 0;
        border: none;
      }

      &:last-child {
        border-bottom: 1px solid #FFFFFF1A;
      }
    }
  }

  .faqs-answer {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    text-align: start;
    transform: translateY(-30px);
    padding-bottom: 0;
    visibility: hidden;
    transition:
            opacity 0.3s ease,
            transform 0.3s ease,
            max-height 0.3s ease,
            padding-bottom 0.3s ease,
            visibility 0.3s ease 0.3s;
  }

  .faqs-list-item.open + .faqs-answer {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    padding-bottom: 20px;
    transform: translateY(0);
  }

  .SVGArrow {
    transition: transform 0.2s ease-in-out;
  }

  .faqs-list-item.open .SVGArrow {
    transform: rotate(180deg);
  }
}

.canopus-switcher {
  width: 210px;
  height: 40px;
  background-color: #FFFFFF1A;
  color: #FFFFFF;
  border-radius: 41px;
  display: flex;
  align-items: center;
  margin-bottom: 81px;
  position: relative;
  cursor: pointer;
  justify-content: center;
}

.canopus-switcher-standalone,
.canopus-switcher-virtual {

  box-sizing: border-box;
  user-select: none;
  height: 32px;
  width: 107px;
  margin: 4px;
  border-radius: 48px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}

.canopus-switcher-toggle-background {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 107px;
  height: 32px;
  background-color: #FFFFFF1A;
  border-radius: 48px;
  transition: 0.3s ease-in-out;
  z-index: -1;
}

.canopus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 88px;
  margin-bottom: 66px;

  &-text {
    &-desc {
      opacity: 60%;
      width: 620px;
      margin-bottom: 29px !important;
    }
  }

  &-server {
    padding-top: 158px;
    margin-bottom: 66px;
    gap: 93px;

    &-text {
      width: 510px !important;

      &-desc {
        opacity: 60%;
      }
    }
  }

  &-title {
    max-width: 700px;
  }

  &-products {
    display: flex;
    text-align: left;
    gap: 50px;
    margin-bottom: 80px;

    &-panel {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 36px;
      border-radius: 24px;
      background-color: #FFFFFF0D;
      outline: 2px solid #346290;
      box-shadow: 10px 10px 37.7px 0 #000000;
      transition: box-shadow 1s ease, outline 1s ease;

      &:hover {
        outline: 3px solid #2980D6;
        box-shadow: 0 0 30px 0 #3566E8;
      }

      & .hr {
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
        position: relative;
        top: 10%
      }

      &-name {
        font-family: "TildaSansSemiBold", sans-serif;
        font-size: 28px;
        line-height: 28px;
        background: linear-gradient(90.88deg, #7892EC 0.37%, #6129D2 89.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

      }

      &-desc {
        font-family: "TildaSansRegular", sans-serif;
        margin-top: 10px;
        color: #FFFFFF99;
        font-size: 18px;
        line-height: 20px;
      }

      &-version {
        margin: 40px 0 45px 0;
        display: flex;
        text-align: center;
        justify-content: center;

        & span {
          width: 144px;
          height: 30px;
          display: flex;
          text-align: center;
          justify-content: center;
          background-color: #FFFFFF1A;
          backdrop-filter: blur(40.5px);
          -webkit-backdrop-filter: blur(40.5px);
          font-weight: 500;
          line-height: 22px;
          border-radius: 10px;
          font-size: 15px;
          align-items: center;
          color: #FFFFFF;
        }

      }

      &-list {
        margin-bottom: 41px;

        &-desc {
          font-family: 'TildaSansMedium', sans-serif;
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        &-item {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          & span {
            margin-left: 11.67px;
            height: 24px;
            display: flex;
            align-items: center;
          }
        }
      }

      & button {
        height: 40px;
        width: 338px;
      }
    }
  }

  &-details {
    font-family: 'TildaSansMedium', sans-serif;
    margin-bottom: 78px;
    width: 297px;
    padding: 0 16px;
    background-color: #FFFFFF1A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 41px;
    color: #FFFFFF99;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.05px;


    & a {
      font-family: 'TildaSansBold', sans-serif;
      cursor: pointer;
      height: 40px;
      margin: 0 0 0 15px;
      color: #FFFFFF;
      opacity: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        margin-left: 15px;

        path {
          transition: stroke 0.3s ease;
        }

      }

      &:hover {
        color: #4670FE;

        & svg path {
          stroke: #4670FE;
        }
      }
    }
  }
}